/* global Excel, Office */

import StaticStorage from "./StaticStorage";
import metadata from '../metadata.json';

//Store the locale-specific strings
interface IStrings {
    EN ,
    AR,
    SP,
    FR,
}
const UIString: IStrings = {
    // JSON object for English strings
    EN:
    {
        "operationDone": "Operation Done! This window will be closed automatically",
        "transactionNotCompleted": "Transaction still not completed",
        "docsUploadError": "Problems in documents signature",
        'sessionExpired': 'Session expired!',
        'uploadingDocs': 'Uploading signed document(s) to App4Legal...',
        'checkStatus': 'Checking status...',
        'sendingDocs': 'Sending document(s) to Emdha...',
        'gettingReady': 'Getting document(s) ready...',
        'authentication': 'Redirecting to Emdha gateway...',
        'error': 'Something went wrong!',               
        'fileAccessDenied': 'Access Denied',
        
    },
    FR:
    {
        "operationDone": "Opération terminée ! Cette fenêtre se fermera automatiquement",
        "transactionNotCompleted": "Transaction toujours pas terminée",
        "docsUploadError": "Problèmes de signature de documents",
        'sessionExpired': 'La session a expiré!',
        'uploadingDocs': 'Téléchargement de document(s) signé(s) vers App4Legal...',
        'checkStatus': "Vérification de l'état...",
        'sendingDocs': "Envoi de documents à Emdha...",
        'gettingReady': "Préparation des documents...",
        'authentication': 'Redirection vers la passerelle Emdha...',
        'error': "Quelque chose s'est mal passé!",
        "loading": "Chargement...",       
    },
    SP:
    {
        "operationDone": "¡Operación terminada! Esta ventana se cerrará automáticamente",
        "transactionNotCompleted": "Transacción aún no completada",
        "docsUploadError": "Problemas en la firma de documentos",
        'sessionExpired': '¡Sesión expirada!',
        'uploadingDocs': 'Subiendo documento(s) firmado(s) a App4Legal...',
        'checkStatus': 'Comprobando estado...',
        'sendingDocs': 'Enviando documentos a Emdha...',
        'gettingReady': 'Preparando los documentos...',
        'authentication': 'Redirigiendo a la puerta de enlace de Emdha...',
        'error': '¡Algo salió mal!',
        "loading": "Cargando...",       
    },
    // JSON object for Arabic strings
    AR:
    {
        "operationDone": "تمت العملية بنجاح! سيتم إغلاق هذه النافذة تلقائيًا",
        "transactionNotCompleted": "التحويل لا يزال غير مكتمل",
        "docsUploadError": "مشاكل في توقيع المستندات",
        'sessionExpired': 'انتهت الجلسة!',
        'uploadingDocs': 'تحميل المستندات الموقعة إلى App4Legal ...',
        'checkStatus': 'جارٍ التحقق من الحالة ...',
        'sendingDocs': 'إرسال المستندات إلى Emdha ...',
        'gettingReady': 'جاري تجهيز المستندات ...',
        'authentication': 'إعادة التوجيه إلى بوابة Emdha ...',
        'error': 'هناك خطأ ما!',
        "loading": "قيد المعالجة...",       
    }
};
export const getText = (key) => {
    let locale = getLocaleStrings();
    return locale[key];
}
export const getLocaleStrings = () => {
    var text;
    let locale = StaticStorage().getItem("lang") ?? "en";
    // Get the resource strings that match the language.
    switch (locale) {
        case 'en': case 'english':
            text = UIString.EN;
            break;
        case 'sp': case 'es': case 'spanish':
            text = UIString.SP;
            break;
        case 'fr': case 'french':
            text = UIString.FR;
            break;
        case 'ar': case 'arabic':
            text = UIString.AR;
            break;
        default:
            text = UIString.EN;
            break;
    }
    return text;
}