import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import './custom.css';
import App4LegalIcon from "./assets/app4legal-logo.png";
import LexzurIcon from "./assets/lexzur-icon-white.png";
import Home from './components/Home';
import EmdhaIcon from './assets/emdha.png';
import Redirection from './components/Redirection';
import Helpers from './helpers/Helpers';
import metadata from "./metadata.json";
import './custom.css';

export const auxButtonColor = '#205081';
export default class App extends Component {
    static displayName = App.name;        
    render() {
        return (
            <div id="AppContainer">
                <div id="Toolbar">
                    <img id="company-logo" src={Helpers.isRebranded() ? LexzurIcon : App4LegalIcon} alt="company-logo" />
                    <div id="Vert-Divider"></div>
                    <img id="EmdhaLogo" alt="Emdha-Icon" src={EmdhaIcon} />                    
                </div>
                <Routes>
                    <Route index={true} element=<Home /> />
                    <Route path="/redirection" element=<Redirection /> />
                </Routes>
                <div id="footer">
                    v.<span>{String(metadata.appVersion)}</span>
                </div>
            </div>
        );
    }
}
export const EMDHA_BASE_URL = "https://esign-dev.emdha.sa/eSign";
export const EMDHA_SIGNER_GATEWAY = "https://gateway.dev.emdha.com.sa";
