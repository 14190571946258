import StaticStorage from '../preferences/StaticStorage';
import metadata from "../metadata.json";

export default class Helpers {
    static logout = () => {
        localStorage.removeItem("url");
        localStorage.removeItem("username");
        localStorage.removeItem("userKey");
        localStorage.removeItem("userId");
        localStorage.removeItem("userImagebase64");
    };
    static getTemplogginData = () => {
        var userData;
        try {
            var url = localStorage.getItem('url');
            var username = localStorage.getItem('username');
            var userKey = localStorage.getItem('userKey');
            var userId = localStorage.getItem('userId');
            var userImagebase64 = localStorage.getItem('userImagebase64');
            userData = {
                url: url,
                username: username,
                userKey: userKey,
                userId: userId,
                userImage: userImagebase64
            };
        } catch (e) {
            console.log(e);
        }
        return userData;
    }
    static getLicensingType = () => {
        var licenseType = localStorage.getItem('licenseType');
        if (!licenseType || licenseType == 'undefined') {
            if (!licenseType || licenseType == 'undefined' || licenseType == undefined) {
                return null;
            }
            else
                localStorage.setItem('licenseType', licenseType)
        }
        return licenseType;
    }
    static saveDataToStorage = (key: string, value: string) => {
        localStorage.setItem(key, value);
    }
    static checkUrl(url) {
        var validURL = url;
        try {
            if (url.substring(url.length - 1) != '/') {
                validURL = url + "/";
            }
            if (!(url.startsWith("http://")) && !(url.startsWith("https://"))) {
                validURL = "http://" + validURL;
            }
        } catch (e) {
        }
        return validURL;
    }
    static getUrlPar = (sParam) => {
        try {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? '' : sParameterName[1];
                }
            }
        } catch (e) {
            return '';
        }
    }
    static ValidateEmail(inputText) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputText)) {
            return (true)
        }
        return (false)
    }
    static getApiChannel() {
        try {
            let channel = StaticStorage().getItem('apiChannel');
            if (channel?.length > 0) {
                return channel;
            }
            else {
                return "core";
            }
        }
        catch (err) {
            return 'core';
        }
    }
    static cleantTextFromTags(text) {
        return text?.replace(/<\/?[^>]+(>|$)/g, "");
    }
    static isRebranded(){
        return metadata.isRebranded;
    }
    static isFromCP() {
        return Helpers.getApiChannel() === 'cp';
    }
    /**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 * @param  {any} row
 * @return {void}     
 */
    static downloadBase64(row) {
        const linkSource = `data:application/pdf;base64,${row.content}`;
        const downloadLink = document.createElement("a");
        const fileName = row.full_name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    static scrollIntoView = (object, panel: string | boolean) => (event, isExpanded: boolean) => {
        object.props.changeAccordion(isExpanded ? panel : false);
    }
    static dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    static formatDate(date, isDateTime = false, isHijri = false, isFormatted = false) {
        if (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                hr = d.getHours(),
                min = d.getMinutes(),
                sec = d.getSeconds(),
                year = d.getFullYear();
            var hour = String(hr);
            var minute = String(min);
            var second = String(sec);
            var ampm = hr >= 12 ? 'pm' : 'am';
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            if (hr < 10) {
                hour = "0" + hr;
            }
            if (hr > 12 && hr < 24) {
                hr = hr - 12;
                hour = String(hr);
            }
            if (hr == 24) {
                hr = 0o0;
                hour = String(hr)
            }
            if (min < 10) {
                minute = "0" + min;
            }
            if (sec < 10) {
                second = "0" + sec;
            }
            if (isDateTime) {

                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            }
            if (isFormatted) {
                return d.toLocaleDateString('en', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
            }
            return [year, month, day].join('-') + " " + [hour, minute, sec].join(':');
        }
        return null;
    }
    static formatDateTimeNoSpecialCharacters(date) {
        if (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                hr = d.getHours(),
                min = d.getMinutes(),
                sec = d.getSeconds(),
                year = d.getFullYear();
            var hour = String(hr);
            var minute = String(min);
            var second = String(sec);
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            if (hr < 10) {
                hour = "0" + hr;
            }
            if (hr == 24) {
                hr = 0o0;
                hour = String(hr)
            }
            if (min < 10) {
                minute = "0" + min;
            }
            if (sec < 10) {
                second = "0" + sec;
            }
            return year + month + day + hour + minute + second;
        }
        return null;
    }
    static compareDates(item, uitext) {
        try {
            var creationDate = new Date(item.modifiedTime || item.lastModifiedDateTime || item.ClientModified);
            var modificationDate = new Date();

            // To calculate the time difference of two dates
            var Difference_In_Time = (modificationDate.getTime() - creationDate.getTime()) / 1000;

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (3600 * 24);
            if (Difference_In_Time < 10)
                return uitext['secondsAgo'];
            if (Difference_In_Time < 60)
                return uitext['minuteAgo'];
            if (Difference_In_Time < 3000)
                return uitext['minutesAgo'];
            if (Difference_In_Time < 3600 * 2)
                return uitext['hourAgo'];
            if (Difference_In_Time < 3600 * 10)
                return Math.floor((Difference_In_Time / 3600)) + ' ' + uitext['specificHoursAgo'];
            if (Helpers.sameDay(modificationDate, creationDate))
                return uitext['today'];
            if (Difference_In_Days > 0.5 && Difference_In_Days <= 1.5)
                return uitext['yesterday'];
            if (item.modifiedTime)
                return this.formatDate(item.modifiedTime);
            if (item.lastModifiedDateTime)
                return this.formatDate(item.lastModifiedDateTime);
            if (item.ServerModified)
                return this.formatDate(item.ClientModified);
            if (item == null) {
                return " - ";
            }
        }
        catch (err) {
            console.log(err);
            return '';
        }
    }
    static sameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }
    static formatTime(date) {
        var d = new Date(date),
            hr = d.getHours(),
            min = d.getMinutes();
        var minute = String(min);
        var hours = String(hr);
        if (hr < 10) {
            hours = "0" + hr;
        }
        if (min < 10) {
            minute = "0" + min;
        }
        return [hours, minute].join(':');
    }
    static char_count(str, letter) {
        var letter_Count = 0;
        for (var position = 0; position < str.length; position++) {
            if (str.charAt(position) == letter) {
                letter_Count += 1;
            }
        }
        return letter_Count;
    }
    static cleaningData(data) {
        var cleanedData = data;
        Object.entries(data).map(([key, value]) => {
            if (typeof value == 'string' || typeof value == 'boolean' || typeof value == 'number') {
                cleanedData[key] = encodeURIComponent(value);
            }
            if (value != null) {
                if (typeof value === 'string' || Array.isArray(value)) {
                    if (value.length == 0)
                        delete cleanedData[key];
                }
            }
            else
                delete cleanedData[key];
        });
        return cleanedData;
    }
    static getFileExtension(fileFullName: string) {
        var re = /(?:\.([^.]+))?$/;
        return re.exec(fileFullName)[1];
    }
    static getFileName(fileFullName: string) {
        return fileFullName?.replace(/\.[^/.]+$/, "");
    }
    static getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    static uniqArray(a) {
        var prims = { "boolean": {}, "number": {}, "string": {} }, objs = [];

        return a.filter(function (item) {
            var type = typeof item;
            if (type in prims)
                return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
            else
                return objs.indexOf(item) >= 0 ? false : objs.push(item);
        });
    }
    static getHostUrl(fileId: string, origin: boolean = false) {
        const port = (window.location.port ? `:${window.location.port}` : '') + (origin ? '' : '/');
        const hostUrl = window.location.protocol + '//' + window.location.hostname + port + (origin ? '' : `wopi/files/${fileId}`);
        return hostUrl;
    }
    static getLanguageSyntax(language: string,) {
        if (language === 'english' || language === 'en')
            return 'en-US';
        if (language === 'arabic' || language === 'ar')
            return 'ar-SA';
        if (language === 'french' || language === 'fr')
            return 'fr-FR';
        if (language === 'spanish' || language === 'sp')
            return 'es-ES';
    }
    static seconds_since_epoch(d) {
        return Math.floor(d);
    }
    static async encodeAccessToken(message: string) {
        return btoa(message);
    }
    static colorLuminance(hex, lum) {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }

        return rgb;
    }
    static toCamel(o) {
        var newO, origKey, newKey, value
        if (o instanceof Array) {
            return o.map(function (value) {
                if (typeof value === "object") {
                    value = Helpers.toCamel(value)
                }
                return value
            })
        } else {
            newO = {}
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                    value = o[origKey]
                    if (value instanceof Array || value?.constructor === Object || value instanceof Date) {
                        value = Helpers.toCamel(value)
                    }
                    newO[newKey] = value
                }
            }
        }
        return newO
    }
    static base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    static blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    static convertBase64ToBlob(fileContent) {
        // Convert the base64 content to array of bytes
        const byteCharacters = atob(fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Convert the bytes array into blob
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        return blob;
    }
}