import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { auxButtonColor, EMDHA_SIGNER_GATEWAY } from "../App";
import { BallTriangle } from 'react-loader-spinner';
import AxiosRequest from '../helpers/AxiosRequest';
import Helpers from '../helpers/Helpers';
import StaticStorage from "../preferences/StaticStorage";
import PDFMerger from 'pdf-merger-js/browser';
export default function Home(props) {        
    const [message, setMessage] = useState(null);
    const [docs, setDocs] = useState([]);
    const [status, setStatus] = useState(null);
    useEffect(() => {
        document.title = "Lexzur - Emdha Signature"       
        window.addEventListener('message', (event) => {
            let payload = event?.data?.payload;
            if (payload) {
                let data = payload.app4legal_emdha;
                if (data) {                    
                    setMessage(data);
                }
            }
        })
        window.opener?.postMessage({
            is_app_loaded: true
        }, '*');
    }, []);
    useEffect(() => {
        if (message) {            
            Object.entries(message).forEach(entry => {
                StaticStorage().setItem(entry[0], entry[1]);
            });
            AxiosRequest.setAppDirection();            
            setStatus("loading");
            let documents = message.documents;
            if (documents) {
                let responses = documents.map(document => AxiosRequest.getFileInfo(document.id));
                Promise.all(responses)
                    .then(async response => {                                                                        
                        setDocs(response);
                        setStatus("gettingReady");
                        let nonPDFDocs = response.filter(doc => doc.extension !== "pdf");
                        let pdfDocs = response.filter(doc => doc.extension === "pdf");
                        let convertedDocs = [];
                        if (nonPDFDocs.length > 0) {
                            //Convert doc to pdf
                            for (var i = 0; i < nonPDFDocs.length; i++) {
                                let nonPdfDoc = nonPDFDocs[i];
                                try {
                                    let convertedDoc = await AxiosRequest.previewDocument(nonPdfDoc);
                                    convertedDocs.push(convertedDoc);
                                }
                                catch (err) {
                                    console.log(err);
                                }
                            }
                        }                        
                        let pdfDocsPromise = pdfDocs.map(pdfDoc => AxiosRequest.getFileContent(pdfDoc.id, true));
                        Promise.all(pdfDocsPromise)
                            .then(contentResponse => {
                                setStatus("sendingDocs");
                                contentResponse = contentResponse.map((contentResp, index) => {
                                    let originalDocumentName = documents.find(doc => String(doc.id) === String(pdfDocs[index].id))?.name;
                                    return ({ ...pdfDocs[index], ...contentResp, name: originalDocumentName })
                                });
                                let allDocsContent = [...contentResponse, ...convertedDocs];
                                mergeDocuments(allDocsContent);                                                               
                            })
                    })
                    .catch(error => {                        
                        setStatus("error");
                    })

            }            
        }
    }, [message])
    async function mergeDocuments(documents){
        //Merge PDFs in one PDF
        const merger = new PDFMerger();

        for (const doc of documents) {
          let blobPDF = Helpers.convertBase64ToBlob(doc.content);          
          await merger.add(blobPDF)
        }

        const mergedPdf = await merger.saveAsBlob();        
        let mergedBase64Pdf = await Helpers.blobToBase64(mergedPdf);  
        mergedBase64Pdf = mergedBase64Pdf.replace("data:application/pdf;base64,", "");
        let firstDocName = documents[0]?.name;
        signDoc(mergedBase64Pdf, firstDocName);
    }
    function getSigneeSignatures() {
        return new Promise((resolve, reject) => {
            AxiosRequest.getModulePreferences('emdha')
                .then(response => {                    
                    if(response?.signees){
                        let signatures = JSON.parse(response?.signees);                        
                        let currentSignatureId = AxiosRequest.getModuleInfo().signatureId;                        
                        let currentSigneeSignatures = signatures.find(signs => signs.id == currentSignatureId)?.signatures;
                        resolve(currentSigneeSignatures);                        
                    }
                    else{
                        resolve(null)
                    }
                })
                .catch(error => {
                    console.error(error);
                    reject(null);
                })
        });
        }
    async function signDoc(doc, docName) {
        const formData = new FormData();        
        formData.append(`files[][0][name]`, docName);
        formData.append(`files[][0][content]`, doc);        
        formData.append("userData", JSON.stringify(message));
        const signeeSignatures = await getSigneeSignatures();
        if(signeeSignatures && signeeSignatures.length > 0){
            signeeSignatures.forEach((signature, index) => {
                formData.append(`signatures[][${index}][page]`, signature.page);
                formData.append(`signatures[][${index}][coordinates][x]`, signature.coordinates.x);
                formData.append(`signatures[][${index}][coordinates][y]`, signature.coordinates.y);
            })
        }
        axios.post('/signature/emdha/sign', formData)
            .then(response => {                
                try {                    
                    let xmlResponse = response.data;
                    if (xmlResponse?.length > 0) {
                        let parser = new DOMParser();
                        let xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
                        if (xmlDoc) {                            
                            let signResponse = xmlDoc.getElementsByTagName("SignResp")[0];
                            if (signResponse) {
                                let responseCode = signResponse.getAttribute("respCode");
                                let txn = signResponse.getAttribute("txn");                                
                                let txnref = btoa(txn + "|" + responseCode);
                                redirectUserToEmdhaPortal(txnref);                                
                            }
                        }
                    }
                }
                catch (err) {

                }
            })
            .catch(error => {                
                if (error.response.status === 400) {
                    let xmlResponse = error.response.data;
                    if (xmlResponse?.length > 0) {
                        let parser = new DOMParser();
                        let xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
                        if (xmlDoc) {
                            //Check if error found
                            let signResponse = xmlDoc.getElementsByTagName("SignResp")[0];
                            if (signResponse) {
                                let errorCode = signResponse.getAttribute("errorCode");
                                let errorMsg = signResponse.getAttribute("errorMessage");
                                if (errorMsg != "") {
                                    setDocs([]);
                                    setStatus(errorMsg)
                                }
                            }
                        }
                    }
                }
                else {
                    setStatus("error");
                }
            })
    }
    const redirectUserToEmdhaPortal = (txnref) => {
        if (txnref) {
            let inputEl = document.getElementById("txnref");
            let formEl = document.getElementById("redirectToEmdha");
            inputEl.value = txnref;
            formEl.setAttribute("action", EMDHA_SIGNER_GATEWAY);
            formEl.submit();            
        }
        else {
            setStatus('error');
        }
    }
    const getStatusText = () => {
        return AxiosRequest.getStatusText(status);                                                                    
    }    
    return (
        <div id="Container">
            <form id="redirectToEmdha" action="" method="post">
                <input type="hidden" id="txnref" name="txnref" />                
            </form>            
            <Loader />
            {<p>{getStatusText()}</p>}
        </div>
    );
}
export const Loader = (props) => {
    return (<BallTriangle
        height={50}
        width={50}
        radius={5}
        color={auxButtonColor}
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
    />)
}