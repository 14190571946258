import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { EMDHA_CHECK_STATUS } from "../App";
import EmdhaIcon from '../assets/emdha.png';
import PdfIcon from '../assets/pdf.svg';
import { Loader } from "./Home";
import StaticStorage from '../preferences/StaticStorage';
import AxiosRequest from "../helpers/AxiosRequest";
import { useParams } from "react-router-dom";

export default function Redirection(props) {
    const [docsStatus, setDocsStatus] = useState(null);
    const [signedDocs, setSignedDocs] = useState([]);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    useEffect(() => {
        var formData = getQueryString();
        axios.post("/signature/emdha/check-api?checkUser=1", formData)
            .then(response => {
                let data = response?.data;
                if (data) {
                    let userData = JSON.parse(data.userData);
                    saveUserDataToStorage(userData);                                        
                    StaticStorage().setItem("lang", userData?.lang);
                    AxiosRequest.setAppDirection();                    
                    setStatus("loading");
                    checkStatus();
                }
                else {
                    setLoading(false);
                    setStatus('sessionExpired');
                }
            })
            .catch(error => {
                console.error(error);
                setStatus("error");
            })        
    }, []);   
    useEffect(() => {
        if (signedDocs.length > 0) {
            var formData = getQueryString();
            axios.post("/signature/emdha/check-api", formData)
                .then(response => {
                    let data = response?.data;
                    if (data) {
                        let docs = data.docs;
                        let mappedDocs = [];
                        signedDocs.forEach(signedDoc => {
                            let docId = signedDoc.getAttribute("id");
                            let doc = docs.find(doc => doc.id === docId);
                            let docName = doc?.name;
                            let docContent = signedDoc.innerHTML;
                            let blobContent = AxiosRequest.b64toBlob(docContent, 'application/pdf');
                            let docLineage = doc?.lineage;                                                        
                            docName = `${docName}_signed.pdf`;
                            mappedDocs.push({ file: new File([blobContent], docName), lineage: docLineage });
                        })
                        if (mappedDocs.length > 0) {
                            //Upload signed documents to App4Legal
                            let uploadPromises = mappedDocs.map(mappedDoc => AxiosRequest.uploadSignedFile(mappedDoc.file, mappedDoc.lineage));
                            Promise.all(uploadPromises)
                                .then(uploadResponses => {
                                    setLoading(false);
                                    setDocsStatus("1");
                                    setTimeout(() => {
                                        window.opener?.postMessage({
                                            is_app_closed: true
                                        }, "*");
                                        window.close();
                                    }, 5000);
                                })
                                .catch(uploadErrors => {
                                    setStatus('error');
                                    setLoading(false);
                                    setDocsStatus("0");
                                })
                        }
                    }
                    else {
                        setLoading(false);
                        setStatus('sessionExpired');
                    }
                })
                .catch(error => {
                    console.error(error);
                    setStatus("error");
                })
        }
    }, [signedDocs]);
    const saveUserDataToStorage = (userData) => {
        StaticStorage().setAll(userData);
    }
    const getQueryString = () => {
        var searchpar = new URLSearchParams(window.location.search);
        let formData = new FormData();
        for (var pair of searchpar.entries()) {
            formData.append(pair[0], pair[1]);
        }
        return formData;
    }
    const checkStatus = () => {
        var formData = getQueryString();
        setStatus("checkStatus");
        axios.post("/signature/emdha/status", formData)
            .then(response => {
                if (response?.data) {
                    try {
                        let xmlResponse = response.data;
                        if (xmlResponse?.length > 0) {
                            let parser = new DOMParser();
                            let xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
                            if (xmlDoc) {
                                let signResponse = xmlDoc.getElementsByTagName("SignResp")[0];
                                if (signResponse) {
                                    let stts = signResponse.getAttribute("status");
                                    let signedDocsEl = signResponse.getElementsByTagName("signedDocs")[0];
                                    let sgndDocs = signedDocsEl?.getElementsByTagName("signedDoc");
                                    let arrayDocs = Array.from(sgndDocs);
                                    setSignedDocs(arrayDocs);
                                    if (arrayDocs.length === 0) {
                                        setStatus("error")
                                    }
                                    else {
                                        setStatus(stts === '1' ? 'uploadingDocs' : 'error');
                                    }
                                }
                            }
                        }                        
                    }
                    catch (err) {
                        setStatus("error");
                    }
                }
            })
            .catch(error => {
                setStatus("error");
            })
    }
    const getDocsStatus = () => {
        switch (docsStatus) {
            case '1': return AxiosRequest.getStatusText("operationDone");
            case '3': case '2': return AxiosRequest.getStatusText("transactionNotCompleted");
            case '0': return AxiosRequest.getStatusText("docsUploadError");
        }
    }
    const getDocsStatusIcon = () => {
        switch (docsStatus) {
            case '1': return <div class="o-circle c-container__circle o-circle__sign--success">
                <div class="o-circle__sign"></div>
            </div>   ;            
            case '0': return <div class="o-circle c-container__circle o-circle__sign--failure">
                <div class="o-circle__sign"></div>
            </div>;
            case '3' :case '2': return "";
        }
    }
    const getStatusText = () => {
        return AxiosRequest.getStatusText(status);                                                                    
    }   
    return (
        <div id="Container">
            {signedDocs?.length > 0 && !docsStatus && <div id="DocsEmdha">
                <div id="Docs-Signed-Container">
                    <img id="PdfSign" alt="PDF-Icon" src={PdfIcon} />
                    <img id="EmdhaSign" alt="Emdha-Icon" src={EmdhaIcon}/>
                </div>                                
            </div>}
            {docsStatus && <section class="c-container">{getDocsStatusIcon()}</section>}
            {docsStatus && <div><p>{getDocsStatus()}</p></div>}
            {loading && <Loader />}
            {loading && <p>{getStatusText()}</p>}
        </div>
    );
}
function base64ToBuffer(str) {
    str = window.atob(str); // creates a ASCII string
    var buffer = new ArrayBuffer(str.length),
        view = new Uint8Array(buffer);
    for (var i = 0; i < str.length; i++) {
        view[i] = str.charCodeAt(i);
    }
    return buffer;
}