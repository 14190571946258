class StaticStorage {
    static instance: StaticStorage;
    public static data: any;
    constructor() {
        StaticStorage.data = {}
    }
    public setItem(key: string, value: any) {
        StaticStorage.data = {
            ...StaticStorage.data,
            [key]: value
        }
    }
    public getItem(key: string) {
        return StaticStorage.data[key];
    }
    public removeItem(key: string) {
        if (Object.keys(StaticStorage.data)?.includes(key)) {
            delete StaticStorage.data[key];
        }
    }
    public getAll() {
        return StaticStorage.data;
    }
    public setAll(data: any) {
        StaticStorage.data = data;
    }
    public static getInstance(): StaticStorage {
        if (!StaticStorage.instance)
            StaticStorage.instance = new StaticStorage();
        return StaticStorage.instance;
    }
}
export default StaticStorage.getInstance;