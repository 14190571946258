
export default class References {
    static getUserPreferencesApi = "modules/api/user_integrations/get/";
    static setUserPreferencesApi = "modules/api/user_integrations/set/";
    static revokeUserPreferencesApi = "modules/api/user_integrations/revoke_access/";
    static getModulePreferencesApi = "modules/api/module_preferences/get/";
    static setModulePreferencesApi = "modules/api/module_preferences/set/";
    static deleteModulePreferencesApi = "modules/api/module_preferences/delete/";
    static editContractApi = "modules/api/contracts/edit/";    

    //V2 Documents
    static loadDocuments = (channel) => `api/v2/${channel}/documents`;
    static getDocumentById = (channel) => `api/v2/${channel}/document/`;
    static getDocumentInfo = (channel) => `api/v2/${channel}/document/info/`;
    static getSignedDocumentInfo = (channel) => `api/v2/${channel}/signed-document/info/`;
    static createDocument = (channel) => `api/v2/${channel}/document`;
    static uploadSignedFile = (channel) => `api/v2/${channel}/signed-document/upload`;
    static uploadFile = (channel) => `api/v2/${channel}/document/upload-file`;
    static replaceFile = (channel) => `api/v2/${channel}/document/replace-file/`;
    static createFolder = (channel) => `api/v2/${channel}/document/create-directory`;
    static updateDocumentMetaData = (channel) => `api/v2/${channel}/document/`;
    static deleteDocument = (channel) => `api/v2/${channel}/document/`;
    static getDownloadLink = (channel) => `api/v2/${channel}/document/public-link/`;
    static downloadDocument = (channel) => `api/v2/${channel}/document/download/`;
    static getDocumentContent = (channel) => `api/v2/${channel}/document/get-content/`;
    static getSignedDocumentContent = (channel) => `api/v2/${channel}/signed-document/get-content/`;
    static getFileLockStatus = (channel) => `api/v2/${channel}/document/get-lock/`;
    static setFileLock = (channel) => `api/v2/${channel}/document/lock/`;
    static refreshFileLock = (channel) => `api/v2/${channel}/document/refresh-lock/`;
    static unlockFile = (channel) => `api/v2/${channel}/document/unlock/`;
    static previewDocument = (channel) => `api/v2/${channel}/document/preview/`;
    static previewSignedDocument = (channel) => `api/v2/${channel}/signed-document/preview/`;
    static listVersionsV2 = (channel) => `api/v2/${channel}/document/list-versions/`;
    static downloadZip = (channel) => `api/v2/${channel}/document/download-zip`;
    static renameFile = (channel) => `api/v2/${channel}/document/rename-file/`;
    static deleteFile = (channel) => `api/v2/${channel}/document/`;
    static deleteDocuments = (channel) => `api/v2/${channel}/document/delete-documents`;
    static renameFolder = (channel) => `api/v2/${channel}/document/rename-directory/`;
    static listFolders = (channel) => `api/v2/${channel}/document/folders-list`;
    static moveDocument = (channel) => `api/v2/${channel}/document/move`;
    static shareDocument = (channel) => `api/v2/${channel}/document/share/`;
    static toggleInAP = (channel) => `api/v2/${channel}/document/toggle-in-ap/`;
    static showHideInCP = (channel) => `api/v2/${channel}/document/show-hide-cp/`;
    static toggleInCP = (channel) => `api/v2/${channel}/document/toggle-in-cp/`;
    static uploadFiles = (channel) => `api/v2/${channel}/document/upload-files`;
    static uploadFolder = (channel) => `api/v2/${channel}/document/upload-directory`;
    static getDocumentTypes = (channel) => `api/v2/${channel}/document/types`;
    static getDocumentStatuses = (channel) => `api/v2/${channel}/document/statuses`;
    static toBeApproved = (channel) => `api/v2/${channel}/document/set-to-be-approved/`;
    static toBeSigned = (channel) => `api/v2/${channel}/document/set-to-be-signed/`;
    static getRecipients = (channel) => `api/v2/${channel}/document/get-recipients/`;
    static shareWithRecipients = (channel) => `api/v2/${channel}/document/share-with-recipients`;
    static checkApiV2 = (channel) => `api/v2/${channel}/check-api`;
    static usersAutoComplete = (channel) => `api/v2/${channel}/autocomplete-users`;
    static templateVariables = (channel) => `api/v2/${channel}/document/template-variables/`;
    static templatesList = (channel) => `api/v2/${channel}/document/templates-list`;
    static generateDocument = (channel) => `api/v2/${channel}/document/generate-document/`;
    static modulePreferencesSet = (channel) =>  `api/v2/${channel}/module-preferences/set`;
    static modulePreferencesGet = (channel) =>  `api/v2/${channel}/module-preferences`;
    static modulePreferencesDelete = (channel) =>  `api/v2/${channel}/module-preferences/delete`;
};